import React, { useContext } from 'react';
import scrollTop from '../helpers/scrollTop';
import displayTZSCurrency from '../helpers/DisplayCurrency';
import Context from '../context';
import addToCart from '../helpers/addToCart';
import { Link } from 'react-router-dom';

const VerticalCard = ({ loading, data = [] }) => {
  const loadingList = new Array(13).fill(null);
  const { fetchUserAddToCart } = useContext(Context);

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id);
    fetchUserAddToCart();
  };

  return (
    <div className="grid gap-2 lg:grid-cols-[repeat(auto-fit,minmax(220px,280px))] md:grid-cols-2  mx-auto px-3 sm:px-3 md:px-4 py-4 overflow-x-auto scrollbar-none">
      {loading
        ? loadingList.map((_, index) => (
            <div
              key={index}
              className="bg-white rounded-md shadow-md min-w-[200px] max-w-[260px] h-auto animate-pulse"
            >
              <div className="h-36 bg-slate-200 rounded-t-md flex justify-center items-center"></div>
              <div className="p-3 space-y-2">
                <div className="h-6 bg-slate-200 rounded w-3/4"></div>
                <div className="h-5 bg-slate-200 rounded w-1/2"></div>
                <div className="h-4 bg-slate-200 rounded w-2/3"></div>
                <div className="flex gap-3 mt-2">
                  <div className="h-6 bg-slate-200 rounded w-1/3"></div>
                  <div className="h-6 bg-slate-200 rounded w-1/4"></div>
                </div>
                <div className="h-8 bg-slate-200 rounded mt-4"></div>
              </div>
            </div>
          ))
        : data.map((product, index) => (
            <Link
              to={`../product/${product?._id}`}
              key={index}
              className="bg-white rounded-md shadow-md min-w-[200px] max-w-[260px] h-auto"
            >
              <div className="h-32 sm:h-36 md:h-40 bg-slate-200 flex justify-center items-center rounded-t-md">
                <img
                  src={product?.productImage[0]}
                  className="object-contain h-full mix-blend-multiply hover:scale-105 transition-transform duration-300"
                  alt={product?.productName}
                />
              </div>
              <div className="p-3 space-y-2">
                <h2 className="font-medium text-sm sm:text-base md:text-lg text-black line-clamp-1">
                  {product?.productName}
                </h2>
                <div className="flex justify-between text-xs sm:text-sm text-slate-500">
                  <p className="capitalize">{product?.category}</p>
                  <p>{product?.condition}</p>
                </div>
                <p className="text-slate-500 text-xs sm:text-sm">{product?.userId?.name}</p>
                <p className="text-xs sm:text-sm text-ellipsis line-clamp-2">
                  {product?.description}
                </p>
                <div className="flex justify-between items-center mt-2">
                  <p className="text-orange-600 font-semibold text-md sm:text-lg">
                    {displayTZSCurrency(product?.sellingPrice)}
                  </p>
                  <p className="text-slate-500 text-xs sm:text-sm line-through">
                    {displayTZSCurrency(product?.price)}
                  </p>
                </div>
                <button
                  onClick={(e) => handleAddToCart(e, product?._id)}
                  className="w-full bg-orange-600 text-white py-1 rounded hover:bg-orange-700 transition-colors font-semibold text-sm sm:text-md"
                >
                  Add to Cart
                </button>
              </div>
            </Link>
          ))}
    </div>
  );
};

export default VerticalCard;
