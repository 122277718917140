import React from 'react';
import CategoryList from '../components/CategoryList';
import BannerProduct from '../components/BannerProduct';
import HorizontalCardproduct from '../components/HorizontalCardproduct';
import VerticalCardproduct from '../components/VerticalCardproduct';

const Home = () => {
  return (
    <div>
      
      <CategoryList/>
      <BannerProduct/>
      <HorizontalCardproduct category={"watches"} heading = {"Popular Watches"}/>
      <HorizontalCardproduct category={"Tablets.Phones"} heading = {"Tablets & Phones"}/>
      <VerticalCardproduct category={""} heading = {"Recommended"}/>
      <VerticalCardproduct category={"Computers"} heading = {"Popular Computers"}/>
      <VerticalCardproduct category={"Computer Accessories"} heading = {"Computer Accessories"}/>
      <VerticalCardproduct category={"Tablets.Phones"} heading = {"Popular Tablets and Phones"}/>
      <VerticalCardproduct category={"watches"} heading = {"Popular Watches"}/>
     <VerticalCardproduct category={"Health & Beauty"} heading = {"Health & Beauty"}/>
     <VerticalCardproduct category={"Fashion & Wear"} heading = {"Fashion & Wear"}/>
     <VerticalCardproduct category={"Jewerly"} heading = {"Jewerly"}/>
      <VerticalCardproduct category={"Cameras & CCTV"} heading = {"Popular CCTV & Cameras"}/>
      <VerticalCardproduct category={"Access Point"} heading = {"Access Points & Routers"}/>
      <VerticalCardproduct category={"Printers"} heading = {"Printers"}/>
      <VerticalCardproduct category={"Printer Appliances"} heading = {"Printer Appliances"}/>
      <VerticalCardproduct category={"Appliances"} heading = {"Smart & Home Appliances"}/>
      <VerticalCardproduct category={"Gadgets"} heading = {"Gadgets & Peripherals"}/>
      <VerticalCardproduct category={"Security"} heading = {"Security"}/>
      {/* <VerticalCardproduct category={"Networking"} heading = {"Networking"}/> */}
      <VerticalCardproduct category={"Telecommunication"} heading = {"Telecommunication"}/>



       </div>
  );
}

export default Home;
