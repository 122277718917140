import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { Link } from 'react-router-dom'
import './style.css'; // Make sure to import the CSS file
import congratsGif from '../assets/gif/congratulations.gif'; // Import the congratulatory GIF


const CategoryList = () => {
    const [categoryProduct, setCategoryProduct] = useState([])
    const [loading, setLoading] = useState(false)

    const categoryLoading = new Array(13).fill(null)

    const fetchCetgoryProduct = async () => {
        setLoading(true)
        const response = await fetch(SummaryApi.categoryProduct.url)
        const dataRepsonse = await response.json()
        setLoading(false)
        setCategoryProduct(dataRepsonse.data)
    }
    useEffect(() => {
        fetchCetgoryProduct()
    }, [])
    return (
        <div className='container w-full text-center max-w-[2560px] mx-auto p-0'>
          <b className='py-5'>Category List</b>  
            <div className='grid grid-cols-4 md:grid-cols-4 lg:flex lg:items-center lg:gap-4 md:gap-6 justify-between overflow-scroll scrollbar-none'>
                {
                    loading ? (

                        categoryLoading.map((el, index) => {
                            return (
                                <div className='h-18 w-18 md:h-18 lg:h-18 rounded-full overflow-hidden bg-slate-200 animate-pulse' key={"categoryLoading" + index}>

                                </div>
                            )
                        })
                    ) :
                        (
                            categoryProduct?.map((product, index) => {
                                return (
                                 <div className="lg:marquee md:flex sm:flex" key={index}>
  <div className="lg:marquee-content md:flex sm:flex">
    <Link
      to={`/product-category?category=${product?.category}`}
      className="cursor-pointer text-center py-4 sm:py-2 w-full flex flex-col items-center gap-4"
      key={index}
    >
      <div className="w-12 h-12 sm:w-10 sm:h-10 md:w-16 md:h-16 rounded-full sm:p-1 md:p-2 bg-slate-200 overflow-hidden">
        <img
          src={product?.productImage[0]}
          alt={product?.category}
          className="h-full w-full object-cover rounded-full mix-blend-multiply transition-transform duration-300 hover:scale-110"
        />
      </div>
      <p className="mt-2 text-xs sm:text-[10px] md:text-sm capitalize text-center w-full max-w-[90px] lg:truncate">
        {product?.category}
      </p>
    </Link>
  </div>
</div>



                                )
                            })
                        )

                }
            </div>
            {/* <div className="congrats-message">
          🎉 Congratulations! 🎉
          <img src={congratsGif} alt="Congratulations" className="congrats-gif" />
        </div> */}
        </div>
    )
}

export default CategoryList
