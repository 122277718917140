import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you're importing Link

const AboutUs = () => {
  return (
    <div className="about-us-page bg-gray-50 py-12">
      <div className="container mx-auto px-6 md:px-20">
        {/* Header Section */}
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-orange-600 mb-4">About ArushaJiJi</h1>
          <p className="text-lg text-gray-600">
            Welcome to ArushaJiJi, your one-stop online shopping mall offering seamless access to sellers, buyers, 
            and service providers from all over Tanzania. Our mission is to provide a reliable platform for shopping, 
            payment, and business solutions in one place.
          </p>
        </header>

        {/* Founder Section */}
<section className="founder-section mb-12 text-center">
  <h2 className="text-2xl font-semibold mb-4">Meet Our Founder and President</h2>
  <div className="flex flex-col items-center">
    <img
      src={require('../assets/images/Screenshot_20240324-143253_1711649937782_1713034710857.png')}
      alt="Greyson Ezekiel Bussa"
      className="w-48 h-48 object-cover rounded-full shadow-md mb-4"
    />
    <p className="text-lg font-semibold">Greyson Ezekiel Bussa</p>
    <p className="text-gray-700 mt-2 max-w-2xl text-center">
      Greyson Ezekiel Bussa, the Founder and President of ArushaJiJi, is a visionary entrepreneur committed to
      transforming the way people shop online in Tanzania. His goal is to create an inclusive platform that
      connects buyers and sellers seamlessly, providing value through secure transactions and reliable logistics support.
      Under his leadership, ArushaJiJi continues to grow, striving to become the leading online shopping mall in the region.
    </p>
  </div>
</section>

{/* CEO Section */}
<section className="ceo-section mb-12 text-center">
  <h2 className="text-2xl font-semibold mb-4">Meet Our CEO</h2>
  <div className="flex flex-col items-center">
    <img
      src={require('../assets/images/CEO-T.png')}
      alt="Ezekiel Gaspar Mlingi"
      className="w-48 h-48 object-cover rounded-full shadow-md mb-4"
    />
    <p className="text-lg font-semibold">Ezekiel Gaspar Mlingi</p>
    <p className="text-gray-700 mt-2 max-w-2xl text-center">
      Ezekiel Gaspar Mlingi, the CEO of ArushaJiJi, brings a wealth of experience and strategic vision to the company.
      He plays a crucial role in driving the platform's growth and ensuring operational excellence. Ezekiel is
      passionate about leveraging technology to empower both buyers and sellers, creating a reliable and innovative
      online marketplace. Under his leadership, ArushaJiJi is on a mission to redefine eCommerce in Tanzania and beyond.
    </p>
  </div>
</section>


        {/* Mission and Vision Section */}
        <section className="mission-vision mb-12">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
              <p className="text-gray-700">
                To empower businesses and individuals through reliable eCommerce solutions by connecting sellers with buyers 
                while offering secure and efficient payment methods.
              </p>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
              <p className="text-gray-700">
                To become the leading online shopping mall in Tanzania by fostering innovation, customer satisfaction, 
                and business growth for all stakeholders.
              </p>
            </div>
          </div>
        </section>

        {/* Payment Integrations Section */}
        <section className="payment-integrations mb-12 text-center">
          <h2 className="text-2xl font-semibold mb-4">Our Payment Integrations</h2>
          <p className="text-gray-700 mb-6">
            We have integrated multiple payment methods to ensure a smooth and secure checkout experience for both buyers and sellers. 
            Our supported payment methods include:
          </p>
          <ul className="list-disc list-inside text-gray-700 inline-block text-left">
            <li>✅ Airtel Money</li>
            <li>✅ Bank Transfers</li>
            <li>✅ Stripe</li>
            <li>🔜 Mpesa</li>
            <li>🔜 Tigopesa</li>
            <li>🔜 Halopesa</li>
            <li>🔜 AzamPay</li>
          </ul>
        </section>

        {/* Subscription Plans Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-center">Subscription Plans for Sellers</h2>
          <p className="text-gray-700 text-center mb-6">
            We offer flexible subscription plans to empower sellers and businesses of all sizes. Choose the plan that fits your 
            needs and grow your business with us.
          </p>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="bg-white p-6 shadow-md rounded-md">
              <h3 className="text-xl font-semibold mb-2">Basic</h3>
              <p className="text-gray-600">Features: Ads through the website, First listed product.</p>
            </div>
            <div className="bg-white p-6 shadow-md rounded-md">
              <h3 className="text-xl font-semibold mb-2">Premium</h3>
              <p className="text-gray-600">Features: Ads through social media, Free delivery.</p>
            </div>
            <div className="bg-white p-6 shadow-md rounded-md">
              <h3 className="text-xl font-semibold mb-2">Extreme</h3>
              <p className="text-gray-600">Features: Premium ads, Extended delivery options.</p>
            </div>
            <div className="bg-white p-6 shadow-md rounded-md">
              <h3 className="text-xl font-semibold mb-2">Super</h3>
              <p className="text-gray-600">Features: All-in-one premium services, Social media ads.</p>
            </div>
            <Link 
              to="/subscription" 
              className="bg-white p-6 shadow-md rounded-md flex items-center justify-center hover:shadow-lg transition">
              <h1 className="text-lg font-bold text-blue-600">See More on our Plans</h1>
            </Link>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <p className="text-gray-700">
            For more information or inquiries, feel free to contact us at{' '}
            <a href="mailto:support@arushajiji.co.tz" className="text-blue-600 underline">
              support@arushajiji.co.tz
            </a>{' '}
            or visit our head office in Arusha.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
