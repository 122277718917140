const tanzaniaCities = [
    { id: 1, label: "Arusha", value: "Arusha" },
    { id: 2, label: "Arusha, Karatu", value: "Arusha, Karatu" },
    { id: 3, label: "Arusha, Longido", value: "Arusha, Longido" },
    { id: 4, label: "Arusha, Monduli", value: "Arusha, Monduli" },
    { id: 5, label: "Arusha, Ngorongoro", value: "Arusha, Ngorongoro" },
    { id: 6, label: "Dar es Salaam", value: "Dar es Salaam" },
    { id: 7, label: "Dar, Kigamboni", value: "Dar, Kigamboni" },
    { id: 8, label: "Dar, Ilala", value: "Dar, Ilala" },
    { id: 9, label: "Dar, Kinondoni", value: "Dar, Kinondoni" },
    { id: 10, label: "Dar, Ubungo", value: "Dar, Ubungo" },
    { id: 11, label: "Dar, Temeke", value: "Dar, Temeke" },
    { id: 12, label: "Dodoma", value: "Dodoma" },
    { id: 13, label: "Dodoma, Kondoa", value: "Dodoma, Kondoa" },
    { id: 14, label: "Dodoma, Mpwapwa", value: "Dodoma, Mpwapwa" },
    { id: 15, label: "Dodoma, Kongwa", value: "Dodoma, Kongwa" },
    { id: 16, label: "Geita", value: "Geita" },
    { id: 17, label: "Geita, Bukombe", value: "Geita, Bukombe" },
    { id: 18, label: "Geita, Chato", value: "Geita, Chato" },
    { id: 19, label: "Geita, Mbogwe", value: "Geita, Mbogwe" },
    { id: 20, label: "Geita, Nyang'hwale", value: "Geita, Nyang'hwale" },
    { id: 21, label: "Iringa", value: "Iringa" },
    { id: 22, label: "Iringa, Kilolo", value: "Iringa, Kilolo" },
    { id: 23, label: "Iringa, Mafinga", value: "Iringa, Mafinga" },
    { id: 24, label: "Iringa, Mufindi", value: "Iringa, Mufindi" },
    { id: 25, label: "Kagera, Bukoba", value: "Kagera, Bukoba" },
    { id: 26, label: "Kagera, Biharamulo", value: "Kagera, Biharamulo" },
    { id: 27, label: "Kagera, Karagwe", value: "Kagera, Karagwe" },
    { id: 28, label: "Kagera, Kyerwa", value: "Kagera, Kyerwa" },
    { id: 29, label: "Kagera, Missenyi", value: "Kagera, Missenyi" },
    { id: 30, label: "Kagera, Muleba", value: "Kagera, Muleba" },
    { id: 31, label: "Kagera, Ngara", value: "Kagera, Ngara" },
    { id: 32, label: "Katavi, Mpanda", value: "Katavi, Mpanda" },
    { id: 33, label: "Katavi, Mlele", value: "Katavi, Mlele" },
    { id: 34, label: "Kigoma", value: "Kigoma" },
    { id: 35, label: "Kigoma, Kasulu", value: "Kigoma, Kasulu" },
    { id: 36, label: "Kigoma, Kibondo", value: "Kigoma, Kibondo" },
    { id: 37, label: "Kigoma, Kakonko", value: "Kigoma, Kakonko" },
    { id: 38, label: "Kigoma, Buhigwe", value: "Kigoma, Buhigwe" },
    { id: 39, label: "Kigoma, Uvinza", value: "Kigoma, Uvinza" },
    { id: 40, label: "Kirimanjaro, Moshi", value: "Kirimanjaro, Moshi" },
    { id: 41, label: "Kirimanjaro, Hai", value: "Kirimanjaro, Hai" },
    { id: 42, label: "Kirimanjaro, Moshi Rural", value: "Kirimanjaro, Moshi Rural" },
    { id: 43, label: "Kirimanjaro, Rombo", value: "Kirimanjaro, Rombo" },
    { id: 44, label: "Kirimanjaro, Same", value: "Kirimanjaro, Same" },
    { id: 45, label: "Kirimanjaro, Mwanga", value: "Kirimanjaro, Mwanga" },
    { id: 46, label: "Kirimanjaro, Siha", value: "Kirimanjaro, Siha" },
    { id: 47, label: "Lindi", value: "Lindi" },
    { id: 48, label: "Lindi, Kilwa", value: "Lindi, Kilwa" },
    { id: 49, label: "Lindi, Liwale", value: "Lindi, Liwale" },
    { id: 50, label: "Lindi, Nachingwea", value: "Lindi, Nachingwea" },
    { id: 51, label: "Lindi, Ruangwa", value: "Lindi, Ruangwa" },
    { id: 52, label: "Manyara, Babati", value: "Manyara, Babati" },
    { id: 53, label: "Manyara, Hanang", value: "Manyara, Hanang" },
    { id: 54, label: "Manyara, Kiteto", value: "Manyara, Kiteto" },
    { id: 55, label: "Manyara, Mbulu", value: "Manyara, Mbulu" },
    { id: 56, label: "Manyara, Simanjiro", value: "Manyara, Simanjiro" },
    { id: 57, label: "Musoma", value: "Musoma" },
    { id: 58, label: "Musoma, Bunda", value: "Musoma, Bunda" },
    { id: 59, label: "Musoma, Butiama", value: "Musoma, Butiama" },
    { id: 60, label: "Musoma, Rorya", value: "Musoma, Rorya" },
    { id: 61, label: "Musoma, Serengeti", value: "Musoma, Serengeti" },
    { id: 62, label: "Musoma, Tarime", value: "Musoma, Tarime" },
    { id: 63, label: "Mbeya", value: "Mbeya" },
    { id: 64, label: "Mbeya,Chunya", value: "Mbeya,Chunya" },
    { id: 65, label: "Mbeya,Kyela", value: "Mbeya,Kyela" },
    { id: 66, label: "Mbeya,Mbarali", value: "Mbeya,Mbarali" },
    { id: 67, label: "Mbeya,Rungwe", value: "Mbeya,Rungwe" },
    { id: 68, label: "Mbeya,Tukuyu", value: "Mbeya,Tukuyu" },
    { id: 69, label: "Zanzibar City", value: "Zanzibar City" },
    { id: 70, label: "Morogoro", value: "Morogoro" },
    { id: 71, label: "Morogoro, Kilosa", value: "Morogoro, Kilosa" },
    { id: 72, label: "Morogoro, Kilombero", value: "Morogoro, Kilombero" },
    { id: 73, label: "Morogoro, Mvomero", value: "Morogoro, Mvomero" },
    { id: 74, label: "Morogoro, Gairo", value: "Morogoro, Gairo" },
    { id: 75, label: "Mtwara", value: "Mtwara" },
    { id: 76, label: "Mtwara, Masasi", value: "Masasi" },
    { id: 77, label: "Mtwara, Nanyumbu", value: "Mtwara, Nanyumbu" },
    { id: 78, label: "Mtwara, Newala", value: "Mtwara, Newala" },
    { id: 79, label: "Mtwara, Tandahimba", value: "Mtwara, Tandahimba" },
    { id: 80, label: "Mwanza", value: "Mwanza" },
    { id: 81, label: "Geita", value: "Geita" },
    { id: 82, label: "Mwanza, Ilemela", value: "Mwanza, Ilemela" },
    { id: 83, label: "Mwanza, Kwimba", value: "Mwanza, Kwimba" },
    { id: 84, label: "Mwanza, Magu", value: "Mwanza, Magu" },
    { id: 85, label: "Mwanza, Misungwi", value: "Mwanza, Misungwi" },
    { id: 86, label: "Mwanza, Nyamagana", value: "Mwanza, Nyamagana" },
    { id: 87, label: "Mwanza, Sengerema", value: "Mwanza, Sengerema" },
    { id: 88, label: "Mwanza, Ukerewe", value: "Mwanza, Ukerewe" },
    { id: 89, label: "Njombe", value: "Njombe" },
    { id: 90, label: "Njombe, Ludewa", value: "Njombe, Ludewa" },
    { id: 91, label: "Njombe, Makambako", value: "Njombe, Makambako" },
    { id: 92, label: "Njombe, Makete", value: "Njombe, Makete" },
    { id: 93, label: "Njombe, Wanging'ombe", value: "Njombe, Wanging'ombe" },
    { id: 94, label: "Njombe, Wete", value: "Njombe, Wete" },
    { id: 95, label: "Njombe, Micheweni", value: "Njombe, Micheweni" },
    { id: 96, label: "Njombe, Chake Chake", value: "Njombe, Chake Chake" },
    { id: 97, label: "Njombe, Mkoani", value: "Njombe, Mkoani" },
    { id: 98, label: "Pwani, Kibaha", value: "Pwani, Kibaha" },
    { id: 99, label: "Pwani, Bagamoyo", value: "Pwani, Bagamoyo" },
    { id: 100, label: "Pwani, Kisarawe", value: "Pwani, Kisarawe" },
    { id: 101, label: "Pwani, Mafia", value: "Pwani, Mafia" },
    { id: 102, label: "Pwani, Mkuranga", value: "Pwani, Mkuranga" },
    { id: 103, label: "Pwani, Rufiji", value: "Pwani, Rufiji" },
    { id: 104, label: "Rukwa, Sumbawanga", value: "Rukwa, Sumbawanga" },
    { id: 105, label: "Rukwa, Kalambo", value: "Rukwa, Kalambo" },
    { id: 106, label: "Rukwa, Nkasi", value: "Rukwa, Nkasi" },
    { id: 107, label: "Rukwa, Songea", value: "Rukwa, Songea" },
    { id: 108, label: "Rukwa, Mbinga", value: "Rukwa, Mbinga" },
    { id: 109, label: "Rukwa, Tunduru", value: "Rukwa, Tunduru" },
    { id: 110, label: "Rukwa, Namtumbo", value: "Rukwa, Namtumbo" },
    { id: 111, label: "Rukwa, Nyasa", value: "Rukwa, Nyasa" },
    { id: 112, label: "Shinyanga", value: "Shinyanga" },
    { id: 113, label: "Shinyanga, Kahama", value: "Shinyanga, Kahama" },
    { id: 114, label: "Shinyanga, Kishapu", value: "Shinyanga, Kishapu" },
    { id: 115, label: "Shinyanga, Bariadi", value: "Shinyanga, Bariadi" },
    { id: 116, label: "Shinyanga, Busega", value: "Shinyanga, Busega" },
    { id: 117, label: "Shinyanga, Itilima", value: "Shinyanga, Itilima" },
    { id: 118, label: "Shinyanga, Maswa", value: "Shinyanga, Maswa" },
    { id: 119, label: "Shinyanga, Meatu", value: "Shinyanga, Meatu" },
    { id: 120, label: "Singida", value: "Singida" },
    { id: 121, label: "Singida, Ikungi", value: "Singida, Ikungi" },
    { id: 122, label: "Singida, Iramba", value: "Singida, Iramba" },
    { id: 123, label: "Singida, Manyoni", value: "Singida, Manyoni" },
    { id: 124, label: "Singida, Mkalama", value: "Singida, Mkalama" },
    { id: 125, label: "Singida, Mwamgongo", value: "Singida, Mwamgongo" },
    { id: 126, label: "Singida, Vwawa", value: "Singida, Vwawa" },
    { id: 127, label: "Singida, Mbozi", value: "Singida, Mbozi" },
    { id: 128, label: "Tunduma", value: "Tunduma" },
    { id: 129, label: "Tunduma, Ileje", value: "Tunduma, Ileje" },
    { id: 130, label: "Tunduma, Momba", value: "Tunduma, Momba" },
    { id: 131, label: "Tunduma, Songwe", value: "Tunduma, Songwe" },
    { id: 132, label: "Tabora", value: "Tabora" },
    { id: 133, label: "Tabora, Igunga", value: "Tabora, Igunga" },
    { id: 134, label: "Tabora, Kaliua", value: "Tabora, Kaliua" },
    { id: 135, label: "Tabora, Nzega", value: "Tabora, Nzega" },
    { id: 136, label: "Tabora, Sikonge", value: "Tabora, Sikonge" },
    { id: 137, label: "Tabora, Urambo", value: "Tabora, Urambo" },
    { id: 138, label: "Tanga", value: "Tanga" },
    { id: 139, label: "Tanga, Handeni", value: "Tanga, Handeni" },
    { id: 140, label: "Tanga, Kilindi", value: "Tanga, Kilindi" },
    { id: 141, label: "Tanga, Korogwe", value: "Tanga, Korogwe" },
    { id: 142, label: "Tanga, Lushoto", value: "Tanga, Lushoto" },
    { id: 143, label: "Tanga, Mkinga", value: "Tanga, Mkinga" },
    { id: 144, label: "Tanga, Muheza", value: "Tanga, Muheza" },
    { id: 145, label: "Tanga, Pangani", value: "Tanga, Pangani" }
  ];
  
  export default tanzaniaCities;
  