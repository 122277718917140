import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CiEdit } from 'react-icons/ci';
import moment from 'moment';
import UpdateUser from '../components/UpdateUser';
import SummaryApi from '../common';
import PaymentForm from '../components/PaymentForm'; 
import '../App.css';

const MyProfile = () => {
  const [allUser, setAllUsers] = useState([]);
  const [allPayments, setAllPayments] = useState([]);
  const [openUpdateUser, setOpenUpdateUser] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [updateUserDetails, setUpdateUserDetails] = useState({
    email: '',
    name: '',
    location: '',
    role: '',
    _id: '',
  });

  // Fetch user data
  const fetchAllUsers = async () => {
    try {
      const response = await fetch(SummaryApi.MyProfile.url, {
        method: SummaryApi.MyProfile.method,
        credentials: 'include',
      });
      const data = await response.json();

      if (data.success) {
        setAllUsers(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Failed to fetch users.');
    }
  };

  // Fetch payment information
  const fetchPaymentInfo = async () => {
    try {
      const response = await fetch(SummaryApi.MyPayment.url, {
        method: SummaryApi.MyPayment.method,
        credentials: 'include',
      });
      const data = await response.json();

      if (data.success) {
        setAllPayments(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Failed to fetch payment information.');
    }
  };

  useEffect(() => {
    fetchAllUsers();
    fetchPaymentInfo();
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen py-8 px-4">
      <h2 className="text-2xl font-bold text-center text-orange-600 mb-8">User Profiles</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {allUser.map((user) => (
          <div
            key={user._id}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between space-y-4"
          >
            {/* Profile Header */}
            <div className="flex items-center justify-between">
              <div>
              <h3 className="text-lg font-semibold text-gray-800">{user.username}</h3>
                <h3 className="text-lg font-semibold text-gray-800">{user.name}</h3>
            
                <p className="text-sm text-gray-500">{user.role}</p>
              </div>
           
              <button
                className="bg-green-100 p-2 rounded-full cursor-pointer hover:bg-green-500"
                onClick={() => {
                  setUpdateUserDetails(user);
                  setOpenUpdateUser(true);
                }}
              >
                <CiEdit size={24} />
              </button>
            </div>

            {/* User Info */}
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Email:</span> {user.email}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Location:</span> {user.location || 'N/A'}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Mobile:</span> {user.phone || 'N/A'}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Joined:</span> {moment(user.createdAt).format('LL')}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Payment Information */}
      <h2 className="text-2xl font-bold text-center text-orange-600 mt-12 mb-8">Payment Info</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {allPayments.map((payment) => (
          <div
            key={payment._id}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between space-y-4"
          >
            {/* Payment Header */}
            <h3 className="text-lg font-semibold text-gray-800">Payment Type: {payment.paymentType }</h3>

            {/* Payment Info */}
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Method:</span> {payment.mobilePaymentType || payment.cardNumber}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Number:</span> {payment.lipaNumber || payment.mobileNumber || payment.cardNumber}
              </p>
              
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Date:</span> {moment(payment.createdAt).format('LL')}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Add Payment Info Button */}
      <div className="mt-8 flex justify-center">
        <button
          className="bg-white text-orange-700 shadow-lg font-bold py-2 px-4 rounded-md hover:bg-slate-100"
          onClick={() => setShowPaymentForm(!showPaymentForm)}
        >
          {showPaymentForm ? 'Close Payment Info' : 'Add Payment Info'}
        </button>
      </div>

      {/* Conditional Rendering of Payment Form */}
      {showPaymentForm && (
        <div className="mt-8">
          <PaymentForm />
        </div>
      )}

      {/* Update User Modal */}
      {openUpdateUser && (
        <UpdateUser
          onClose={() => setOpenUpdateUser(false)}
          profilePic={updateUserDetails.profilePic}
          name={updateUserDetails.name}
          username={updateUserDetails.username}
          email={updateUserDetails.email}
          location={updateUserDetails.location}
          role={updateUserDetails.role}
          userId={updateUserDetails._id}
          callFunc={fetchAllUsers}
        />
      )}
    </div>
  );
};

export default MyProfile;
