import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Outlet } from 'react-router-dom';
// import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
// import Login from './pages/Login';
// import ForgotPassword from './pages/ForgotPassword';
// import SignUp from "./pages/SignUp";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryApi from './common';
import Context from "./context";
import { useDispatch } from 'react-redux';
import { setUserDetails } from './stores/userSlice';
import PaymentForm from './components/PaymentForm';
// import AdminPanel from './pages/AdminPanel';

function App() {
const dispatch = useDispatch()
const [cartProductCount, setCartProductCount] = useState(0)
const fetchUserDetails = async()=>{
  const dataResponse = await fetch(SummaryApi.current_user.url,{
    method : SummaryApi.current_user.method,
    credentials : `include`
  })
  const dataApi = await dataResponse.json()

if (dataApi.success) {
  dispatch(setUserDetails(dataApi.data))
}
}
const fetchUserAddToCart = async() =>{
  const dataResponse = await fetch(SummaryApi.addToCartProductCount.url,{
    method : SummaryApi.addToCartProductCount.method,
    credentials : `include`
  })
  const dataApi = await dataResponse.json()

setCartProductCount(dataApi?.data?.count)
}
  useEffect(()=>{
/**user Details */
fetchUserDetails()

// user details cart product
fetchUserAddToCart()
  },[])
  return (
    <>
    <Context.Provider value={{
        fetchUserDetails, //user details fetch
        cartProductCount, //current user add product to cart
        fetchUserAddToCart 
       }}>
            <ToastContainer position='top-center' />
    <Header/>
    <main className='min-h-[calc(100vh-80px)] pt-16'>
    {/* <PaymentForm /> */}
    <Outlet/>
    </main>
    <Footer/>
    </Context.Provider>
    </>


    // <div>
    //    <Context.Provider value={{
    //     fetchUserDetails //user details fetch
    //    }}>
    //   <ToastContainer />
    //   <Routes>
    //     <Route path="/" element={<Header />} />
    //   </Routes>
    //   <div className='min-h-[calc(100vh-120px)]'>
    //     <main>
    //       <Routes>
    //         <Route path="/login" element={<Login />} />
    //         <Route path="/forgot-password" element={<ForgotPassword />} />
    //         <Route path="/sign-up" element={<SignUp />} />
    //         <Route path="/" element={<Home />} />
    //         <Route path="/admin-panel" element={<AdminPanel />} />

    //       </Routes>
    //     </main>
    //   </div>
    //   <Routes>
    //     <Route path="/" element={<Footer />} />
    //   </Routes>
    //   </Context.Provider>
    // </div>
  );
}

export default App;
