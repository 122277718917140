import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SummaryApi from '../common'
import VerticalCard from '../components/VerticalCard'

const SearchProduct = () => {
    const query = useLocation()
const [data,setData] = useState([])
const [loading,setLoading] = useState(false)

    console.log("query",query.search)
    const fetchProduct = async()=>{
      setLoading(true)
const response = await fetch(SummaryApi.searchProduct.url+query.search)
const dataResponse = await response.json()
setLoading(false)

setData(dataResponse.data)
console.log("dataResponse",dataResponse)
    }
    useEffect(()=>{
        fetchProduct()
    },[query])
  return (
    <div className='container w-full max-w-[2560px] mx-auto p-4'>

      {
        loading && (
          <p className='text-center text-lg'>Loading, Please Wait...</p>
        )
      }
      
<p className='text-lg font-medium my-3'>Search results : {data?.length}</p>   
{
  data.length === 0 && !loading && (
   <p className='bg-gray text-xl text-center p-4'>No data Found for your search, try another key word...!</p>
  )
    
  }

  {
    data.length !== 0 && !loading && (
     
          <VerticalCard loading={loading} data={data} />
      
    )
  }

  </div>
  )
}

export default SearchProduct