import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import UploadProduct from '../components/UploadProduct'
import SummaryApi from '../common'
import AdminProductCard from '../components/AdminProductCard'

const MyProducts = () => {
  const [openUploadproduct, setOpenUploadProduct] = useState(false)
  const [MyProducts,setAllProduct] = useState([])

  const fetchAllProduct = async() => {
    const response = await fetch(SummaryApi.MyProducts.url, {
      method: SummaryApi.MyProducts.method,
      credentials: 'include',
      headers: {
          "content-type": 'application/json'
      }
  })
  const responseData = await response.json()
  if (responseData.success) {
    setAllProduct(responseData.data)
  }
  }
  useEffect(()=>{
    fetchAllProduct()
  },[])
  
  return (
    <div>
  <div className='bg-white py-2 px-2 flex justify-between items-center'>
<h2 className='font-bold text-lg'>All Products</h2>
<button className='border-2 border-orange-400 text-orange-400 hover:border-orange-400 hover:bg-orange-400 hover:text-white py-2 px-4 rounded-full' onClick={()=>setOpenUploadProduct(true)}>Upload Products</button>
  </div>
{/* all Products */}
<div className='flex flex-wrap items-center gap-3 py-4 h-[calc(100vh-190px)] overflow-y-scroll'>
  {
    MyProducts.length === 0 ? (
      <div className='bg-white w-full flex ml-auto justify-center p-4 font-bold text-orange-700 font-medium'>
        <p>Welcome to <b className='text-blue-400'>ARUSHAJIJI ONLINE SHOPPING MALL</b> ! &nbsp; Please upload products to start selling them online.</p>
      </div>
    ) : (
      MyProducts.map((product, index) => (
        <AdminProductCard data={product} key={index + "allProductForSeller"} fetchdata={fetchAllProduct} />
      ))
    )
  }
</div>




        {/*upload products component */}
  
  
  {
    openUploadproduct && (
<UploadProduct onClose = {()=>setOpenUploadProduct(false)} fetchData={fetchAllProduct}/>
    )
  }
    </div>
  )
}

export default MyProducts
