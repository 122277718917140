// Logo.js
import React from 'react';
import logoImage from '../assets/Logo/arushajiji online shopping mall.png'; // Import the PNG file

const Logo = ({ w, h }) => {
  return (
    <img src={logoImage} alt="Logo" width={w} height={h} className='mix-blend-multiply object-scale-down' />
  );
}

export default Logo;
