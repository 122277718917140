import React, { useEffect, useState } from 'react'
import imageA from "../assets/banners/img1.webp";
import imageB from "../assets/banners/img3.jpg";
import imageC from "../assets/banners/img4.jpg";

import imageD from "../assets/banners/img1_mobile.jpg";
import imageE from "../assets/banners/img3_mobile.jpg";
import imageF from "../assets/banners/img4_mobile.jpg";


import { FaAnglesRight } from "react-icons/fa6";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";





const BannerProduct = () => {
    const [loading, setLoading] = useState(true)
    const loadingList = new Array(13).fill(null)
    const [currentImage, setCurrentImage] = useState(0)
    const desktopImages = [
        imageA,
        imageB,
        imageC
    ]

    const mobileImages = [
        imageD,
        imageE,
        imageF
    ]
    
    const nextImage = () => {
        setLoading(true)
        if (desktopImages.length -1 > currentImage) {
            setCurrentImage(preve => preve+1)
            setLoading(false)

        }
    }

    const preveImage = () => {
        setLoading(true)
        if (currentImage != 0) {
            setCurrentImage(preve => preve-1)
            setLoading(false)

        }
    }
    useEffect(()=>{
        const interval = setInterval(() => {
            if (desktopImages.length -1 > currentImage) {
                nextImage()
            }else{
                setCurrentImage(0)
            }
            
        }, 5000);
        return () => clearInterval(interval)
    },[currentImage])
    return (
        <div className='container w-full max-w-[2560px] mx-auto px-4 rounded'>
            <div className=' h-56 md:h-72 -w-full bg-slate-200 relative '>
                <div className='absolute z-10 h-full w-full flex items-center'>
               <div className='flex justify-between w-full text-2xl'>
               <button onClick={preveImage} className='bg-white shadow-md rounded-full p-1 '> <FaAngleLeft /></button>
                    <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1 '><FaAngleRight /></button>
               </div>
                </div>

                {/* desktop and tablet version */}

                <div className=' hidden md:flex h-full w-full overflow-hidden  '>

                    {
                        loading ? (
                            loadingList.map((imageURL, index) => {
                                return (
                                    <div className='w-full bg-orange-600 flex justify-center items-center h-full min-w-full animate-pulse min-h-full transition-all' key={imageURL} style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                                        {/* <img src={imageURL} className='w-full h-full' /> */}
                                        <p className='text-white animate-pulse transition-all justify-center items-center flex'>Loading ads...</p>
                                    </div>
                                )
                            })
                        ) : (
                            desktopImages.map((imageURL, index) => {
                                return (
                                    <div className='w-full h-full min-w-full min-h-full transition-all' key={imageURL} style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                                        <img src={imageURL} className='w-full h-full' />
                                    </div>
                                )
                            })
                        )
                      
                    }
                </div>




                 {/* mobile version */}
                 <div className='flex h-full w-full overflow-hidden md:hidden '>

{
     loading ? (
        loadingList.map((imageURL, index) => {
            return (
                <div className='w-full bg-orange-600 flex justify-center items-center h-full min-w-full animate-pulse min-h-full transition-all' key={imageURL} style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                    {/* <img src={imageURL} className='w-full h-full' /> */}
                    <p className='text-white animate-pulse transition-all justify-center items-center flex'>Loading ads...</p>
                </div>
            )
        })
    ) : (
    mobileImages.map((imageURL, index) => {
        return (
            <div className='w-full h-full min-w-full min-h-full transition-all' key={imageURL} style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                <img src={imageURL} className='w-full h-full object-fill' />
            </div>
        )
       })
    )
}
</div>
            </div>

        </div>


    )
}

export default BannerProduct