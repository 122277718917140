import React, { useEffect, useState, useRef } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FaBars, FaRegCircleUser } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import ROLE from '../common/role';

const SellerPanel = () => {
  const user = useSelector((state) => state?.user?.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef();

  // Redirect non-seller users to the homepage
  useEffect(() => {
    if (user?.role !== ROLE.SELLER) {
      navigate('/');
    }
  }, [user, navigate]);

  // Close the menu when clicking outside of it (mobile version)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false); // Close the menu when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle menu open/close
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <div className="min-h-[calc(100vh-120px)] w-full flex flex-col md:flex-row">
      {/* Sidebar for Desktop and Collapsible Menu for Mobile */}
      <aside
        ref={menuRef}
        className={`bg-white min-h-full md:w-60 w-full md:block fixed md:static top-0 left-0 transition-transform duration-300 
          ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 z-50 shadow-md`}
      >
        {/* Close Icon for Mobile */}
        <div className="md:hidden flex justify-end p-4">
          <button onClick={() => setIsMenuOpen(false)} className="text-2xl">
            <FaTimes />
          </button>
        </div>

        <div className="h-32 flex justify-center items-center flex-col p-4">
          <div className="text-5xl cursor-pointer relative flex justify-center">
            {user?.profilePic ? (
              <img
                src={user?.profilePic}
                className="w-20 h-20 rounded-full"
                alt={user?.name}
              />
            ) : (
              <FaRegCircleUser className="text-gray-400" />
            )}
          </div>
          <p className="capitalize text-lg font-semibold mt-2">{user?.name}</p>
          <p className="text-sm text-slate-400">My Account</p>
        </div>

        {/* Navigation Links */}
        <nav className="p-4">
          <Link
            to="/"
            className="block px-2 py-2 hover:bg-orange-500 hover:text-white rounded"
            onClick={() => setIsMenuOpen(false)} // Close menu on click
          >
            Home
          </Link>
          <Link
            to="my-products"
            className="block px-2 py-2 hover:bg-orange-500 hover:text-white rounded"
            onClick={() => setIsMenuOpen(false)} // Close menu on click
          >
            My Products
          </Link>
          <Link
            to="all-orders"
            className="block px-2 py-2 hover:bg-orange-500 hover:text-white rounded"
            onClick={() => setIsMenuOpen(false)} // Close menu on click
          >
            All Orders
          </Link>
          <Link
            to="subscribe"
            className="block bg-orange-600 text-white text-center font-bold py-3 mt-4 rounded hover:bg-orange-500"
            onClick={() => setIsMenuOpen(false)} // Close menu on click
          >
            Subscribe
          </Link>
        </nav>
      </aside>

      {/* Hamburger Icon for Mobile */}
      <div className="md:hidden p-4 flex justify-between items-center bg-orange-400">
        <h1 className="text-white font-bold text-xl">Seller Dashboard</h1>
        <button onClick={toggleMenu} className="text-white text-2xl">
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Main Content */}
      <main className="w-full h-full p-4 mt-3 md:mt-0">
        <Outlet />
      </main>

      {/* Overlay for Mobile Menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden"
          onClick={() => setIsMenuOpen(false)} // Close menu when clicking on the overlay
        ></div>
      )}
    </div>
  );
};

export default SellerPanel;
