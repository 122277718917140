import React, { useContext, useEffect, useRef, useState } from 'react'
import { Await, Link } from 'react-router-dom'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayTZSCurrency from '../helpers/DisplayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'
import addToCart from '../helpers/addToCart'
import Context from '../context'

const VerticalCardproduct = ({ category, heading }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const loadingList = new Array(13).fill(null)

    const [scroll,setScroll] = useState(0)
    const scrollElement = useRef()


    const {fetchUserAddToCart} = useContext(Context)

    const handleAddToCart = async(e,id) =>{
       await addToCart(e,id) 
    fetchUserAddToCart()
    }
    const fetchData = async () => {
        setLoading(true)
        const categoryProduct = await fetchCategoryWiseProduct(category)
        setLoading(false)
        

        setData(categoryProduct?.data)
    }


    useEffect(() => {
        fetchData()
    }, [])
    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300
    }
    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300
    }
    return (
        <div className='container w-full max-w-[2560px] mx-auto px-4 my-6 relative'>

            <h2 className='md:text-lg text-xl font-semibold py-4'>{heading}</h2>
            {/* <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6 lg:gap-24 overflow-auto scrollbar-none" ref={scrollElement}>  */}{/* uncomment this for grid view */}
            <div className='flex items-center gap-4 md:gap-6 overflow-x-scroll scrollbar-none transition-all' ref={scrollElement}>

            <button  className='bg-white shadow-md rounded-full p-1 absolute left-0 text-lg  ' onClick={scrollLeft}> <FaAngleLeft /></button>{/* comment this for grid view */}
            <button  className='bg-white shadow-md rounded-full p-1 absolute right-0 text-lg  ' onClick={scrollRight}><FaAngleRight /></button> {/* comment this for grid view */}
                {
                    loading ? (
                        loadingList.map((product, index) => {
                            return (
                                <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
  <div className='h-48 bg-slate-200 min-w-[280px] md:min-w-[145px] flex justify-center items-center p-1 py-2'>
    {/* Placeholder for product image */}
    <div className='w-full h-full bg-slate-200 animate-pulse'></div>
  </div>
  <div className='p-4 grid gap-3'>
    {/* Placeholder for product name */}
    <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black rounded-full animate-pulse bg-slate-200 p-1 py-2'></h2>
    
    {/* Placeholder for category and condition */}
    <div className='flex gap-3 justify-between'>
      <p className='capitalize w-full text-xs sm:text-sm animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>
      <p className='capitalize w-full text-xs sm:text-sm animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>
    </div>

    {/* Placeholder for seller name */}
    <p className='text-ellipsis line-clamp-1 text-sm animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>

    {/* Placeholder for description */}
    <p className='text-ellipsis line-clamp-1 text-sm animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>

    {/* Placeholder for pricing */}
    <div className='flex gap-3'>
      <p className='text-lg animate-pulse w-full rounded-full bg-slate-200 p-1 py-2'></p>
      <p className='text-sm line-through animate-pulse w-full rounded-full bg-slate-200 py-2'></p>
    </div>

    {/* Placeholder for Add to Cart button */}
    <button className='text-sm text-white px-3 py-2 font-semibold animate-pulse rounded-full bg-slate-200'></button>
  </div>
</div>

                            )
                        })                    ) : (
                        data?.map((product, index) => {
                            return (
<Link to={"product/" + product?._id} key={index} className='w-full sm:w-1/2 md:w-1/3 lg:min-w-[320px] lg:max-w-[280px] bg-white rounded-sm shadow'>
  <div className='h-36 sm:h-40 md:h-48 bg-slate-200 p-2 sm:p-3 md:p-4 flex justify-center items-center'>
    <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt={product?.productName} />
  </div>
  <div className='p-2 sm:p-3 md:p-4 grid gap-2'>
    <h2 className='font-medium text-base sm:text-lg md:text-xl text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2> {/* Larger product name */}
    <div className='flex pag-2 justify-between'>
      <p className='capitalize text-xs sm:text-sm text-slate-500'>{product?.category}</p> {/* Smaller category */}
      <p className='capitalize text-xs sm:text-sm text-slate-400'>{product?.condition}</p> {/* Smaller condition */}
    </div>
    <p className='capitalize text-ellipsis line-clamp-1 text-slate-500'>{product?.userId?.name}</p>
    <p className='text-ellipsis line-clamp-1 text-xs sm:text-sm'>{product?.description}</p>
    <div className='flex gap-2'>
      <p className='text-orange-600 font-semibold text-lg sm:text-xl'>{displayTZSCurrency(product?.sellingPrice)}</p> {/* Larger selling price */}
      <p className='text-slate-500 text-xs sm:text-sm line-through p-1'>{displayTZSCurrency(product?.price)}</p>
    </div>
    <div className='flex justify-center items-center'>
      <button onClick={(e) => handleAddToCart(e, product?._id)} className='text-sm sm:text-md bg-orange-600 w-full sm:w-auto hover:bg-orange-700 text-white px-2 sm:px-3 rounded py-1 sm:py-0.5 font-bold'>
        Add to Cart
      </button>
    </div>
  </div>
</Link>


                            )
                        })
                    )
                    
                }
            </div>

        </div>
    )
}

export default VerticalCardproduct
