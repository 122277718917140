import React from 'react'
import { FaShoppingBag } from "react-icons/fa"
import { GiWorld } from "react-icons/gi";
import { MdOutlinePayment } from "react-icons/md";
import logoIconCart from "../assets/Logo/cart-one.png";
import { MdVerified } from "react-icons/md";
import { Link } from 'react-router-dom';
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='bg-slate-200'>
      <div className='container w-full max-w-[2560px] mx-auto p-4'>
        <div className='lg:grid hidden gap-6 justify-between border-b pb-1 mb-3 border-slate-300'>
          <div className='grid gap-3 sm:flex sm:gap-3'>
            <a className='text-slate-500 py-3 grid max-w-[310px]'>
              <p><FaShoppingBag className='h-10 w-10 text-orange-600' /></p>
              <b>Great Value</b>
              Millions of items to select Continuous promotions Personalized products
            </a>
            <a className='text-slate-500 py-3 grid max-w-[310px]'>
              <p><GiWorld className='h-10 w-10 text-orange-600' /></p>
              <b>Worldwide Delivery</b>
              We Deliver and Operate in Tanzania Over all cities and Regions.
            </a>
            <a className='text-slate-500 py-3 grid max-w-[310px]'>
              <p><MdOutlinePayment className='h-10 w-10 text-orange-600' /></p>
              <b>Safe Payment</b>
              Pay with popular and secure payment methods.
            </a>
            <a className='text-slate-500 py-3 grid max-w-[310px]'>
              <img src={logoIconCart} alt="Logo" className='h-14 w-14 text-orange-200 mix-blend-multiply object-scale-down' />
              <b>Shop with Confidence</b>
              With verified sellers from all cities and regions and maximum handling of your order from Arushajiji team until Delivery
            </a>
            <a className='mb-5 text-slate-500 py-3 grid max-w-[310px]'>
              <p><MdVerified className='h-10 w-10 text-orange-600' /></p>
              <b>Verified Sellers</b>
              Thousands of Verified Sellers and Businesses from Tanzania across all Regions and Cities
            </a>
          </div>

          <div className='flex flex-col gap-6 sm:flex-row sm:max-w-[1200px] items-center justify-between mx-auto'>
            <div className='grid gap-3 sm:flex-1'>
              <a className='text-slate-500 max-w-[450px] py-3 grid'>
                <b>FAQs</b>
                <a> 
                  <a href='' className='hover:text-orange-400'>How to signup as seller</a>, 
                  <a href='' className='hover:text-orange-400'>How to register as customer</a>, 
                  <a href='' className='hover:text-orange-400'>How to make payment</a>, 
                  <a href='' className='hover:text-orange-400'>How we handle your payment until you get your Delivery</a>
                </a>
              </a>
            </div>
            
            <div className='grid gap-3 sm:flex-1'>
              <a className='text-slate-500 py-3 grid'>
                <b>About Arushajiji</b>
                <Link to={'/privacy-policy'} className='hover:text-orange-400'>Privacy Policy</Link>
              </a>
            </div>
            
            <div className='grid gap-3 sm:flex-1'>
              <a className='mb-5 py-5 grid font-medium text-lg'>
                <b>Visit</b> 
                <a className='text-blue-400' href='https://tecfinex.co.tz' target='_blank' rel='noopener noreferrer'>tecfinexgroup</a>
              </a>
            </div>
          </div>
        </div>
        <div className='fixed bottom-0 left-0 w-full bg-slate-200 p-3 md:relative'>
  <p className='text-center text-blue-500 font-bold text-xs sm:text-[8px] md:text-[10px] lg:text-[14px]' title="go to website">
    Powered By <a href='https://tecfinex.co.tz' target='_blank' rel='noopener noreferrer'>tecfinexgroup</a>
  </p>
  <p className='text-center font-bold text-xs sm:text-[8px] md:text-[10px] lg:text-[14px]' title="back to website">
    &copy; {currentYear} Arushajiji. All Rights Reserved
  </p>
</div>


      </div>
    </footer>
  )
}

export default Footer
