import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import moment from "moment";
import displayTZSCurrency from '../helpers/DisplayCurrency';
import { Link } from 'react-router-dom';


const OrderPage = () => {
  const [data, setData] = useState([])
  const fetchOrderDetails = async () => {
    const response = await fetch(SummaryApi.getOrder.url, {
      method: SummaryApi.getOrder.method,
      credentials: 'include',

    })
    const responseData = await response.json()

    setData(responseData.data)
    console.log("order list", responseData)
  }
  useEffect(() => {
    fetchOrderDetails()
  }, [])
  return (
    <div>
      <h2 className='bg-orange-400 text-white font-medium text-lg text-center justify-center'>My Orders</h2>
      {
        !data[0] && (
          <div className='bg-white w-full grid ml-auto justify-center p-4 font-bold text-orange-700 font-medium'>
          <p>You don't have any Orders Available right Now <b className='text-blue-400'></b> ! &nbsp; You will see all Completed Orders here after purchasing products.</p>
          <p className='text-center py-2 text-blue-400 font-bold'><Link to={'/'}>Go Shopping?</Link></p>
        </div>
        )
      }
      <div className='p-4 w-full' >
        {
          data.map((item, index) => {
            return (
              <div key={item.userid + index} >
                <p className='font-bold text-sm'>{moment(item.createdAt).format('LLL')}</p>
                <div className='border rounded'>
                  <div className='flex justify-between flex-col lg:flex-row '>

                    <div className='grid gap-1'>
                      {
                        item.productDetails.map((product, index) => {
                          return (
                            <div key={product.productId + index} className='flex gap-3 bg-slate-100'>
                              <div className='w-28 h-28 bg-slate-200'>
                              <img src={product.image[0]} className='w-28 h-28 object-scale-down p-2 mix-blend-multiply' />
                              </div>
                              <div>
                                <div className='font-bold text-lg ellipsis line-clamp-1'>{product.name}</div>
                                <div className='font-bold text-lg ellipsis line-clamp-1'>{product.sellerId}</div>

                                <div className='flex items-center gap-5 mt-1'>
                                  <p> Quantity {product.quantity}</p>
                                  <div className='text-orange-500'>
                                    {displayTZSCurrency(product.price)}
                                  </div>
                                </div>

                              </div>
                            </div>

                          )
                        })
                      }
                    </div>

                    <div className='flex flex-col gap-4 p-2 min-w-[300px]'>
                      <div>
                        <div className='text-lg font-medium'>Payment Details</div>
                        <p className='ml-1 capitalize'>Payment Method : {item.paymentDetails.payment_method_type[0]}</p>
                        <p className='ml-1 capitalize'>Payment Status : <b className='text-green-500'> {item.paymentDetails.payment_status}</b> </p>
                        <p className='ml-1'>Transaction ID : <c className='text-green-500'> {item.paymentDetails.paymentId}</c></p>

                      </div>
                      <div>
                        <div className='ml-1 font-medium capitalize'>Shipping Details</div>
                        {
                          item.
                            shipping_options.map((shipping, index) => {
                              return (
                                <div key={shipping.shipping_rate} className='ml-1 capitalize'>
                                  Shipping Amount : {displayTZSCurrency(shipping.shipping_amount)}
                                </div>
                              )
                            })
                        }
                      </div>


                    </div>

                  </div>
                  
                    <div className='font-bold ml-auto w-fit lg:text-lg mr-1 text-orange-600'>
                      Total Amount : {displayTZSCurrency(item.totalAmount)}
                    </div>


                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default OrderPage