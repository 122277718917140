import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import productCategory from '../helpers/productCategory';
import VerticalCard from '../components/VerticalCard';
import SummaryApi from '../common';

const CategoryProduct = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const urlCategoryListinArray = urlSearch.getAll('category');

  const urlCategoryListObject = {};
  urlCategoryListinArray.forEach(el => {
    urlCategoryListObject[el] = true;
  });

  const [selectCategory, setSelectCategory] = useState(urlCategoryListObject);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [showCategories, setShowCategories] = useState(false); // State for toggling categories

  const fetchData = async () => {
    const response = await fetch(SummaryApi.filterProduct.url, {
      method: SummaryApi.filterProduct.method,
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        category: filterCategoryList
      })
    });

    const dataResponse = await response.json();
    setData(dataResponse?.data || []);
  };
  const handleOnChangeSortBy = (e) => {
    const { value } = e.target;
    setSortBy(value);

    if (value === 'asc') {
      setData(prev => prev.sort((a, b) => a.sellingPrice - b.sellingPrice));
    }

    if (value === 'dsc') {
      setData(prev => prev.sort((a, b) => b.sellingPrice - a.sellingPrice));
    }
  };
  const handleSelectCategory = (e) => {
    const { value, checked } = e.target;

    setSelectCategory((prev) => ({
      ...prev,
      [value]: checked
    }));
  };

  useEffect(() => {
    fetchData();
  }, [filterCategoryList]);

  useEffect(() => {
    const arrayOfCategory = Object.keys(selectCategory).filter(categoryKeyName => selectCategory[categoryKeyName]);
    setFilterCategoryList(arrayOfCategory);

    const urlformat = arrayOfCategory.map((el, index) => `category=${el}`).join('&&');
    navigate('/product-category?' + urlformat);
  }, [selectCategory]);



  return (
  <div className="container mx-auto">
    {/* Desktop Layout */}
    <div className="hidden lg:flex">
      {/* Categories */}
      <div className="w-1/5 p-4 border-r border-gray-300">
            {/* sort by */}
          <div className=''>
            <h3 className='text-lg font-medium text-slate-500 text-base uppercase border-b-2 pb-1 border-slate-300'>Sort by</h3>
            <form className='text-sm flex-col gap-2 py-2'>
              <div className='flex items-center gap-3'>
                <input type='radio' name='sortBy' checked={sortBy === 'asc'} value='asc' onChange={handleOnChangeSortBy} />
                <label>Price - Low to High</label>
              </div>
              <div className='flex items-center gap-3'>
                <input type='radio' name='sortBy' checked={sortBy === 'dsc'} value='dsc' onChange={handleOnChangeSortBy} />
                <label>Price - High to Low</label>
              </div>
            </form>
          </div>

        <form>
          {productCategory.map((categoryName, index) => (
            <div className="flex items-center gap-3 py-1" key={index}>
              <input
                type="checkbox"
                name="category"
                checked={selectCategory[categoryName?.value]}
                value={categoryName?.value}
                id={categoryName?.value}
                onChange={handleSelectCategory}
              />
              <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
            </div>
          ))}
        </form>
      </div>

      {/* Products */}
      <div className="w-4/5 px-6">
        <p className="font-medium text-lg my-2 text-slate-500">
          Search Results: {data.length}
        </p>
        <div className="min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]">
          {data.length !== 0 && <VerticalCard data={data} loading={loading} />}
        </div>
      </div>
    </div>

 {/* Mobile Layout */}
<div className="lg:hidden flex">
 
  {/* Categories - 20% */}
  <div className="max-w-[calc(30%)] fixed  max-h-[calc(100vh-120px)]] overflow-y-scroll max-h-[calc(100vh-120px)]  p-2 border-b   bg-gray-300">
    <div className='flex flex-col'>
            <div className='flex items-center gap-3'>
              <input type='radio' name='sortBy' checked={sortBy === 'asc'} value='asc' onChange={handleOnChangeSortBy} />
              <label>Price - Low to High</label>
            </div>
            <div className='flex items-center gap-3'>
              <input type='radio' name='sortBy' checked={sortBy === 'dsc'} value='dsc' onChange={handleOnChangeSortBy} />
              <label>Price - High to Low</label>
            </div>
          </div>
    <p className="font-semibold mb-4 text-sm">Categories</p>
    <form>
      {productCategory.map((categoryName, index) => (
        <div className="flex items-center border-b border-slate-200 p-2 hover:bg-slate-200 gap-2 py-2" key={index}>
        <input
          type="checkbox"
          name="category"
          checked={selectCategory[categoryName?.value]}
          value={categoryName?.value}
          id={categoryName?.value}
          onChange={handleSelectCategory}
          className="absolute opacity-0 cursor-pointer" // Hides the checkbox
        />
        <label 
          htmlFor={categoryName?.value} 
          className="text-xs text-gray-700 cursor-pointer"
        >
          {categoryName?.label}
        </label>
      </div>
      
      ))}
    </form>
  </div>

  {/* Products - 80% */}
  <div className="max-w-[calc(70%)] ml-[calc(30%)] overflow-y-scroll max-h-[calc(110%)] p-1 ">
    <p className="font-medium text-sm text-slate-700 mb-4">
      Search Results: <span className="font-bold">{data.length}</span>
    </p>
    <div className="gap-2">
      {data.length !== 0 && <VerticalCard className="mb-2"  data={data} loading={loading} />}
    </div>
  </div>
</div>

  </div>
);

};

export default CategoryProduct;
