import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const PaymentForm = () => {
  const [paymentType, setPaymentType] = useState(''); // Select between Credit Card or Mobile Payment
  const [paymentInfo, setPaymentInfo] = useState({
    cardNumber: '',
    expiry: '',
    cvc: '',
    mobilePaymentType: '',
    paymentMode: '',
    mobileNumber: '',
    lipaNumber: '', // New state for Lipa Number
  });

  const [errors, setErrors] = useState({});

  // Handle Payment Type Change
  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
    // Reset paymentInfo when changing payment type
    setPaymentInfo({
      cardNumber: '',
      expiry: '',
      cvc: '',
      mobilePaymentType: '',
      paymentMode: '',
      mobileNumber: '',
      lipaNumber: '',
    });
    setErrors({});
  };

  // Handle Form Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentInfo((prev) => ({ ...prev, [name]: value }));
  };

  // Credit Card Number Validation
  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-digits
    if (value.length > 16) value = value.slice(0, 16); // Limit to 16 digits

    const firstDigit = value[0];
    if (firstDigit && !['4', '5', '2'].includes(firstDigit)) {
      setErrors((prev) => ({
        ...prev,
        cardNumber: 'Card must start with 4 (Visa) or 5/2 (MasterCard).',
      }));
    } else {
      setErrors((prev) => ({ ...prev, cardNumber: '' }));
    }

    setPaymentInfo((prev) => ({ ...prev, cardNumber: value.replace(/(\d{4})(?=\d)/g, '$1 ') }));
  };

  // Expiry Date Auto-Formatting (MM/YY)
  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length > 4) value = value.slice(0, 4); // Limit to MMYY
    if (value.length >= 2) value = `${value.slice(0, 2)}/${value.slice(2)}`;

    setPaymentInfo((prev) => ({ ...prev, expiry: value }));

    // Validate format and future date
    if (!value.match(/^(0[1-9]|1[0-2])\/\d{2}$/)) {
      setErrors((prev) => ({ ...prev, expiry: 'Invalid expiry date format.' }));
      return;
    }

    const [month, year] = value.split('/').map(Number);
    const currentYear = new Date().getFullYear() % 100; // Get last two digits of the year
    const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      setErrors((prev) => ({ ...prev, expiry: 'Expiry date must be in the future.' }));
    } else {
      setErrors((prev) => ({ ...prev, expiry: '' }));
    }
  };

  // Mobile Number Handling (+255 Constant)
  const handleMobileNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const firstDigit = value[0];

    if (value.length > 9) value = value.slice(0, 9); // Limit to 9 digits
    if (firstDigit && !['6', '7'].includes(firstDigit)) {
      setErrors((prev) => ({
        ...prev,
        mobileNumber: 'Mobile number should start with 6 or 7.',
      }));
    }

    setPaymentInfo((prev) => ({ ...prev, mobileNumber: value }));
    if (value.length < 9) {
      setErrors((prev) => ({ ...prev, mobileNumber: 'Mobile number must be 9 digits.' }));
    } else {
      setErrors((prev) => ({ ...prev, mobileNumber: '' }));
    }
  };

  // Lipa Number Handling
  const handleLipaNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length > 8) value = value.slice(0, 8); // Limit to 8 digits

    setPaymentInfo((prev) => ({ ...prev, lipaNumber: value }));

    const { mobilePaymentType } = paymentInfo;
    const validLength = ['Airtel Money', 'Mpesa'].includes(mobilePaymentType) ? [5, 6, 7, 8] : [6, 7, 8];
    if (!validLength.includes(value.length)) {
      setErrors((prev) => ({ ...prev, lipaNumber: 'Lipa number must be 5-8 digits.' }));
    } else {
      setErrors((prev) => ({ ...prev, lipaNumber: '' }));
    }
  };

  const validateCardInfo = () => {
    const { cardNumber, expiry, cvc } = paymentInfo;
    if (cardNumber.replace(/\s/g, '').length !== 16) {
      setErrors((prev) => ({ ...prev, cardNumber: 'Card number must be 16 digits.' }));
      return false;
    }
    if (!expiry.match(/^(0[1-9]|1[0-2])\/\d{2}$/)) {
      setErrors((prev) => ({ ...prev, expiry: 'Invalid expiry date.' }));
      return false;
    }
    if (cvc.length !== 3) {
      setErrors((prev) => ({ ...prev, cvc: 'CVC must be 3 digits.' }));
      return false;
    }
    return true;
  };

  const validateMobilePayment = () => {
    const { mobilePaymentType, mobileNumber, lipaNumber } = paymentInfo;
    if (!mobilePaymentType) {
      toast.error('Please select a mobile payment type.');
      return false;
    }
    if (paymentInfo.paymentMode === 'mobile' && mobileNumber.length !== 9) {
      setErrors((prev) => ({ ...prev, mobileNumber: 'Mobile number must be exactly 9 digits.' }));
      return false;
    }
    if (paymentInfo.paymentMode === 'lipa' && ![5, 6, 7, 8].includes(lipaNumber.length)) {
      setErrors((prev) => ({ ...prev, lipaNumber: 'Lipa number must be 5-8 digits.' }));
      return false;
    }
    return true;
  };

  const handleCardNumberBlur = () => {
    if (paymentInfo.cardNumber.replace(/\s/g, '').length < 16) {
      setErrors((prev) => ({ ...prev, cardNumber: 'Card number must be 16 digits.' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let isValid = true;

    if (paymentType === 'credit') {
      isValid = validateCardInfo();
    } else if (paymentType === 'mobile') {
      isValid = validateMobilePayment();
    }

    if (!isValid) return;

    try {
      const response = await fetch(SummaryApi.updateUserPayment.url, {
        method: SummaryApi.updateUserPayment.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...paymentInfo, paymentType }),
      });

      const data = await response.json();
      if (data.success) {
        toast.success('Payment information submitted successfully!');
      } else {
        toast.error(data.message || 'Failed to submit payment.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <form className="max-w-md mx-auto bg-white p-8 rounded shadow-lg" onSubmit={handleSubmit}>
      <h2 className="text-xl font-bold text-orange-700 mb-4">Add Payment Info</h2>

      {/* Payment Type Selection */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Payment Type</label>
        <select
          value={paymentType}
          onChange={handlePaymentTypeChange}
          className="w-full p-2 border rounded"
          required
        >
          <option value="">Select Payment Type</option>
          <option value="credit">Credit Card</option>
          <option value="mobile">Mobile Payment</option>
        </select>
      </div>

      {/* Credit Card Fields */}
      {paymentType === 'credit' && (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Card Number</label>
            <input
              type="text"
              name="cardNumber"
              value={paymentInfo.cardNumber}
              onChange={handleCardNumberChange}
              onBlur={handleCardNumberBlur}
              placeholder="XXXX XXXX XXXX XXXX"
              className={`w-full p-2 border rounded ${errors.cardNumber ? 'border-red-500' : ''}`}
              required
            />
            {errors.cardNumber && <p className="text-red-500 text-sm">{errors.cardNumber}</p>}
          </div>

          <div className="flex mb-4 space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium mb-2">Expiry (MM/YY)</label>
              <input
                type="text"
                name="expiry"
                value={paymentInfo.expiry}
                onChange={handleExpiryChange}
                placeholder="MM/YY"
                className={`w-full p-2 border rounded ${errors.expiry ? 'border-red-500' : ''}`}
                required
              />
              {errors.expiry && <p className="text-red-500 text-sm">{errors.expiry}</p>}
            </div>

            <div className="flex-1">
              <label className="block text-sm font-medium mb-2">CVC</label>
              <input
                type="text"
                name="cvc"
                value={paymentInfo.cvc}
                onChange={handleInputChange}
                placeholder="CVC"
                className={`w-full p-2 border rounded ${errors.cvc ? 'border-red-500' : ''}`}
                required
              />
              {errors.cvc && <p className="text-red-500 text-sm">{errors.cvc}</p>}
            </div>
          </div>
        </>
      )}

{/* Mobile Payment Fields */}
{paymentType === 'mobile' && (
  <>
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2">Mobile Payment Type</label>
      <select
        name="mobilePaymentType"
        value={paymentInfo.mobilePaymentType}
        onChange={handleInputChange}
        className="w-full p-2 border rounded"
        required
      >
        <option value="">Select Mobile Payment Type</option>
        <option value="Mpesa">Mpesa</option>
        <option value="AirtelMoney">Airtel Money</option>
        <option value="TigoPesa">Tigo Pesa</option>
        <option value="HaloPesa">Halo Pesa</option>
        {/* Add more payment options as needed */}
      </select>
    </div>

    <div className="mb-4">
      <label className="block text-sm font-medium mb-2">Select Payment Option</label>
      <select
        name="paymentOption"
        value={paymentInfo.paymentOption} // Ensure this is part of your paymentInfo state
        onChange={handleInputChange} // Use the same handler for simplicity
        className="w-full p-2 border rounded"
        required
      >
        <option value="">Select Payment Option</option>
        <option value="mobileNumber">Mobile Number</option>
        <option value="lipaNumber">Lipa Number</option>
      </select>
    </div>

    {/* Mobile Number Input */}
    {paymentInfo.paymentOption === 'mobileNumber' && (
      <div className="mb-4 flex items-center space-x-2">
        <label className="block text-sm font-medium mb-2">Mobile Number</label>
        <span className="text-gray-500">+255</span>
        <input
          type="text"
          name="mobileNumber"
          value={paymentInfo.mobileNumber}
          onChange={handleMobileNumberChange}
          placeholder="Mobile Number (9 digits)"
          className={`w-full p-2 border rounded ${errors.mobileNumber ? 'border-red-500' : ''}`}
          required
        />
        {errors.mobileNumber && <p className="text-red-500 text-sm">{errors.mobileNumber}</p>}
      </div>
    )}

    {/* Lipa Number Input */}
    {paymentInfo.paymentOption === 'lipaNumber' && (
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Lipa Number</label>
        <input
          type="text"
          name="lipaNumber"
          value={paymentInfo.lipaNumber}
          onChange={handleLipaNumberChange}
          placeholder="Lipa Number (5-8 digits)"
          className={`w-full p-2 border rounded ${errors.lipaNumber ? 'border-red-500' : ''}`}
          required
        />
        {errors.lipaNumber && <p className="text-red-500 text-sm">{errors.lipaNumber}</p>}
      </div>
    )}
  </>
)}

      <button
        type="submit"
        className="w-full bg-orange-600 text-white font-bold py-2 rounded hover:bg-orange-500 transition duration-200"
      >
        Submit Payment Info
      </button>
    </form>
  );
};

export default PaymentForm;
