import React, { useState } from 'react'
import ROLE from '../common/role'
import { IoMdClose } from "react-icons/io";
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const ChangeUserRole = ({
  name,
  username,
  email,
  role,
  userId,
  onClose,
  callFunc,
}) => {
  const [userRole,setUserRole] = useState(role)
  const [setname,setNewname] = useState(name)
  const [setUsername,setNewUsername] = useState(username)
  const handleOnChangeSelect = (e) =>{
    setUserRole(e.target.value)
    console.log(e.target.value)
  }
  const handleOnChangeUser = (e) =>{
    setNewUsername(e.target.value)
    console.log(e.target.value)
  }
  const handleOnChangename =(e)=>{
    setNewname(e.target.value)
  }
  const updateUserRole = async() => {
const fetchResponse = await fetch(SummaryApi.updateUser.url,{
  method : SummaryApi.updateUser.method,
  credentials : 'include',
  headers : {
    "content-type" : "application/json"
  },
  body : JSON.stringify({
    userId : userId,
    role : userRole,
    name : setname,
    username : setUsername
  })
})

const responseData = await fetchResponse.json()
if (responseData.success) {
  toast.success(responseData.message)
  onClose()
  callFunc()
}
console.log("role updated",responseData) 
  }
  return (
    <div className='top-0 bottom-0 left-0 right-0 fixed absolute w-full h-full z-10 flex justify-between items-center  bg-black bg-opacity-50 '>
       <div className='mx-auto bg-white shadow-md p-4 w-full max-w-sm'>
      
      <button className='block ml-auto' onClick={onClose}>
        <IoMdClose/>
      </button>
      
      <h1 className='pb-4 text-lg font-medium'> ChangeUserRole</h1>
      <input
                    type='text'
                    required
                    value={setname}
                    onChange={handleOnChangename}
                    name='name'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Name'
                  />
                  <input
                    type='text'
                    required
                    value={setUsername}
                    onChange={handleOnChangeUser}
                    name='username'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Username/Business Name'
                  />
                
      <p>Email :  {email}</p>

<div className='flex items-center justify-between'>
<p>Role</p>
   <select className='border px-4 py-1' value={userRole} onChange={handleOnChangeSelect}>
    {
    Object.values(ROLE).map(el => {
        return(
            <option value={el} key={el}>{el}</option>
        )
       
    })
    }
   
   </select>
</div>
<button onClick={updateUserRole} className='w-fit mx-auto block border py-1 px-3 bg-orange-600 hover:bg-orange-700 text-white rounded-full'>Change Role</button>
       </div>
        </div>
  )
}

export default ChangeUserRole
