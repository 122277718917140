import React, { useContext, useState } from 'react';
import Logo from './Logo';
import './style.css';
import { FaRegUserCircle } from "react-icons/fa";
import { FcPrivacy } from "react-icons/fc";
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { CiMenuFries } from "react-icons/ci";
import { CiLogin } from "react-icons/ci";
import { BiLogOut } from "react-icons/bi";
import { IoCreateOutline } from "react-icons/io5";
import { BiSolidUserDetail } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { useDispatch, useSelector } from "react-redux";
import SummaryApi from '../common';
import { toast } from "react-toastify";
import { setUserDetails } from '../stores/userSlice';
import ROLE from '../common/role';
import Context from '../context';


const Header = () => {
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()
  const [menuDisplay, setMenuDisplay] = useState(false)
  const [menuLinkDisplay, setMenuLinkDisplay] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false);

  const context = useContext(Context)
  const Navigate = useNavigate()
  const searchInput = useLocation()
  const URLsearch = new URLSearchParams(searchInput?.search)
  const searchQuery = URLsearch.getAll("sr")

  const [search, setSearch] = useState(searchQuery)
  const [isAnimating, setIsAnimating] = useState(false);

  const handleMenuClick = () => {
    setIsAnimating(true);
    setMenuLinkDisplay(prev => !prev);

    // Remove the animation class after the animation is done
    setTimeout(() => {
      setIsAnimating(false);
    }, 300); // Duration should match the CSS transition duration
  };

  const handleSearchToggle = () => {
    setSearchVisible(prev => !prev);
  };

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    })
    const data = await fetchData.json()

    if (data.success) {
      toast.success(data.message)
      dispatch(setUserDetails(null))
      Navigate("/")
    }
    if (data.error) {
      toast.error(data.message)
    }
  }
  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    if (value) {
      Navigate(`/search?sr=${value}`)
    } else {
      Navigate('search')
    }
  }
  return (

    <header className='container w-full max-w-[2560px] h-16 fixed z-50 md:mb-10 '>
      <div>
        <div className='h-full shadow-md w-full max-w-[2560px] flex items-center bg-white container mx-auto lg:px-4 px-1 justify-between'>
          <div className='justify-center gap-0'>
            <Link to={"/"}>
              <Logo w={180} h={180} />
              
            </Link>
          </div>
          <div className='border focus-within:shadow rounded-full pl-3 hidden lg:flex items-center w-full justify-between max-w-sm'>
            <input value={search} onChange={handleSearch} type='text' className='w-full outline-none' placeholder='What are you looking?...' />
            <div className='text-lg min-w-[50px] flex items-center justify-center text-white rounded-r-full h-8 bg-orange-600'>
              <GrSearch />
            </div>
          </div>


          <div className='flex items-center gap-4 lg:gap-7'>
          <div 
            className='text-2xl cursor-pointer lg:hidden flex justify-center' 
            onClick={handleSearchToggle}
          >
            <GrSearch />
          </div>
            <div className='relative flex gap-4 lg:gap-7 justify-center'>

{
  user?._id && (
    <div
      className='text-2xl space-x-2 md:text-xs sm:text-sm cursor-pointer relative flex justify-center items-center'
      onClick={() => setMenuDisplay(preve => !preve)}
    >
      {user?.profilePic ? (
        <img
          src={user?.profilePic}
          className='w-10 h-10 md:w-6 md:h-6 sm:w-5 sm:h-5 rounded-full'
        />
      ) : (
        <FaRegUserCircle className='w-10 h-10 md:w-6 md:h-6 sm:w-5 sm:h-5' />
      )}
    </div>
  )
}




              {
                menuDisplay && (

                  <div className='absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded'>
                    <nav>
                      {
                        user?.role === ROLE.ADMIN ? (
                          <div className='grid'>
                            <Link to={"/admin-panel/all-products"} className='whitespace-nowrap md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>Admin Panel</Link>
                            <Link to={"/MyOrders"} className='whitespace-nowrap md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>My Orders</Link>
                            <Link to={"/MyProfile"} className='whitespace-nowrap md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>My Profile</Link>

                          </div>
                        )
                          :
                          user?.role === ROLE.SELLER ? (
                            <div className='grid'>
                              <Link to={"/seller-panel/my-products"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>My Panel</Link>
                              <Link to={"/MyOrders"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Order</Link>
                              <Link to={"/MyProfile"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Profile</Link>


                            </div>
                          ) : (
                            <div className='grid'>
                              <Link to={"/MyOrders"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Order</Link>
                              <Link to={"/MyProfile"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Profile</Link>
                              {/* <Link to={"/profile"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> Profile</Link> */}

                            </div>
                          )
                      }
                    </nav>
                  </div>
                )
              }
{
              user?._id && (
                <Link to={"/cart"} className='text-2xl mt-2 mx-auto items-center space-x-2 relative'>
                  <span><FaShoppingCart /></span>
                  <div className='bg-orange-600 -top-2 -right-3 absolute rounded-full h-5 text-white w-5 p-2 flex items-center justify-center'>
                    <p className='text-sm -ml-0.5'>{context?.cartProductCount}</p>
                  </div>
                </Link>
              )
            }
              <div
                className={`text-2xl items-center space-x-2 lg:gap-7 text-orange-600 cursor-pointer gap-4 relative flex justify-center transition-transform duration-300 ${isAnimating ? 'scale-125' : ''} font-bold`}
                onClick={handleMenuClick}>
               <b><CiMenuFries /></b> 
              </div>
            </div>

            

            {

              menuLinkDisplay && (

                <div className='absolute bg-white bottom-0 top-11 right-0 h-fit p-2 mr-10 shadow-lg rounded'>
                  <nav>
                    <div>
                      {
                        user?._id ? (
                          <div>
                            <span className='flex items-center space-x-2'>
                            <BiLogOut className='text-xl font-bold' />
                            <Link onClick={handleLogout} className='whitespace-nowrap hover:text-orange-600 font-bold block hover:bg-slate-100 p-2'>Logout</Link>
                            </span>
                <span className='flex items-center space-x-2'>
                                <BiSolidUserDetail className='text-xl' />
                                <Link to={"/about-arushajiji"} onClick={() => setMenuLinkDisplay(preve => !preve)} className='whitespace-nowrap block hover:bg-slate-100 p-2'>About ArushaJiJi</Link>
                              </span>
                            <span className='flex items-center space-x-2'>
                                <FcPrivacy className='text-xl' />
                                <Link to={"/privacy-policy"} onClick={() => setMenuLinkDisplay(preve => !preve)} className='whitespace-nowrap block hover:bg-slate-100 p-2'>Privacy Policy</Link>
                              </span>
                          </div>
                          

                        )
                          : (
                            <div>
                              <span className='flex items-center space-x-2'>
                                <CiLogin className='text-xl' /> {/* Adjust the size as needed */}
                                <Link to={"/Login"} onClick={() => setMenuLinkDisplay(preve => !preve)} className='whitespace-nowrap  hover:text-orange-600block hover:bg-slate-100 p-2'>Login</Link>
                              </span>

                              <span className='flex items-center space-x-2'>
                                <IoCreateOutline className='text-xl' />
                                <Link to={"/sign-up"} onClick={() => setMenuLinkDisplay(preve => !preve)} className='whitespace-nowrap hover:text-orange-600 block hover:bg-slate-100 p-2'>Register</Link>
                              </span>
                          <span className='flex items-center space-x-2'>
                                <BiSolidUserDetail className='text-xl' />
                                <Link to={"/about-arushajiji"} onClick={() => setMenuLinkDisplay(preve => !preve)} className='whitespace-nowrap block hover:bg-slate-100 p-2'>About ArushaJiJi</Link>
                              </span>
                          
                              <span className='flex items-center space-x-2'>
                                <FcPrivacy className='text-xl' />
                                <Link to={"/privacy-policy"} onClick={() => setMenuLinkDisplay(preve => !preve)} className='whitespace-nowrap hover:text-orange-600 block hover:bg-slate-100 p-2'>Privacy Policy</Link>
                              </span>
                            </div>

                          )
                      }
                    </div>
                  </nav>
                </div>
              )
              
            }
            
          </div>
        </div>



        {searchVisible && (
            <div className='border focus-within:shadow rounded flex lg:hidden items-center w-full min-w-[260px] mx-auto justify-between'>
              <input 
                value={search} 
                onChange={handleSearch} 
                type='text' 
                className='pl-3 outline-none h-8 w-full min-w-[260px]' 
                placeholder='What are you looking for?...' 
              />
              <div className='text-lg min-w-[50px] flex items-center justify-center text-white rounded-r h-8 bg-orange-600'>
                <GrSearch />
              </div>
            </div>
          )}

      </div>
    </header>


  );
}

export default Header;
// import React from 'react'

// const Header = () => {
//   return (
//     <div>Header</div>
//   )
// }

// export default Header
